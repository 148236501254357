"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetUploaderContextProvider = void 0;
const react_1 = __importStar(require("react"));
const react_dropzone_1 = require("react-dropzone");
const react_context_1 = require("@guiker/react-context");
const AssetUploaderContext_1 = require("../../context/AssetUploaderContext");
const use_bulk_asset_uploader_context_1 = require("../../hooks/use-bulk-asset-uploader-context");
const AssetUploaderContextProvider = ({ acceptedFileType = ['image/*', 'application/pdf'], allowMultipleFiles, assets: initAssets = [], children, name, groupName, onChange, withThumbnail = false, }) => {
    const [assets, setAssets] = react_1.useState(initAssets);
    react_1.useEffect(() => {
        setAssets(initAssets);
    }, [initAssets]);
    const bulkUpdateContext = use_bulk_asset_uploader_context_1.useBulkAssetUploaderContext({ errorIfUndefined: false });
    const addFiles = (files) => {
        const existingAssetCount = assets.length;
        const newAssets = files.map((file, index) => ({
            fileName: file.name,
            presignedDownloadUrl: URL.createObjectURL(file),
            name: `${name}[${existingAssetCount + index}]`,
            mimeType: file.type,
            size: file.size,
            file,
        }));
        setAssets([...assets, ...newAssets]);
        onChange && onChange(newAssets);
        bulkUpdateContext && bulkUpdateContext.addAssets(groupName, newAssets);
    };
    const removeAsset = (index) => {
        bulkUpdateContext === null || bulkUpdateContext === void 0 ? void 0 : bulkUpdateContext.removeAsset(groupName, index);
    };
    const dropZoneFunctions = react_dropzone_1.useDropzone({
        accept: acceptedFileType,
        multiple: allowMultipleFiles,
        onDrop: addFiles,
    });
    const value = Object.assign({ assets, removeAsset, withThumbnail }, dropZoneFunctions);
    return (react_1.default.createElement(AssetUploaderContext_1.AssetUploaderContext.Provider, { value: value }, react_context_1.renderChildren(children, value)));
};
exports.AssetUploaderContextProvider = AssetUploaderContextProvider;
