"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiFormAction = exports.ApiForm = void 0;
const react_1 = __importStar(require("react"));
const components_library_1 = require("@guiker/components-library");
const react_hook_form_1 = require("@guiker/react-hook-form");
const data_tracking_context_1 = require("@guiker/data-tracking-context");
const react_query_1 = require("@guiker/react-query");
const ApiFormContext_1 = require("./ApiFormContext");
const renderChildren = function (children, props) {
    if (children instanceof Function) {
        return children(props);
    }
    else {
        return children;
    }
};
const ApiFormAction = (_a) => {
    var { onClick, options = {}, children } = _a, spacingProps = __rest(_a, ["onClick", "options", "children"]);
    const { formName, setIsProcessing } = ApiFormContext_1.useApiFormContext();
    const { formState, trigger, getValues } = react_hook_form_1.useFormContext();
    const { errors } = formState;
    const { sendEvent } = data_tracking_context_1.useDataTrackingContext();
    const [processApiCall, { isLoading }] = react_query_1.useMutation(onClick, options);
    const sendFormEvent = (event) => (formName ? sendEvent(event) : undefined);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isLoading) {
            return;
        }
        setIsProcessing(true);
        sendFormEvent({ event: 'formSubmitAttempt', formName });
        const isValid = options.skipValidation || (yield trigger());
        if (isValid) {
            processApiCall(getValues(), {
                onSuccess: () => sendEvent({ event: 'formSubmitSuccess', formName }),
                onError: (error) => {
                    const formErrorDetails = `${error.name}: ${error.message}`;
                    sendFormEvent({ event: 'formError', formName, formErrorDetails });
                },
                onSettled: () => setIsProcessing(false),
            });
        }
        else {
            if (errors && Object.keys(errors).length > 0) {
                const formErrorDetails = JSON.stringify(errors);
                sendFormEvent({ event: 'formError', formName, formErrorDetails });
            }
            setTimeout(() => setIsProcessing(false), 2000);
        }
    });
    return (react_1.default.createElement(components_library_1.Box, Object.assign({ onClick: handleClick }, spacingProps), children));
};
exports.ApiFormAction = ApiFormAction;
const NotInitializedApiForm = (_a) => {
    var { formOptions } = _a, props = __rest(_a, ["formOptions"]);
    const _b = formOptions || {}, { reValidateMode = 'onBlur' } = _b, otherFormOptions = __rest(_b, ["reValidateMode"]);
    const formMethods = react_hook_form_1.useForm(Object.assign(Object.assign({}, otherFormOptions), { reValidateMode }));
    return react_1.default.createElement(WrappedApiForm, Object.assign({ formMethods: formMethods }, props));
};
const WrappedApiForm = (_a) => {
    var _b;
    var { beforeValidation, onSubmit, children, formMethods: methods, apiOptions = {}, skipIfIsNotDirty = true, width, formName } = _a, props = __rest(_a, ["beforeValidation", "onSubmit", "children", "formMethods", "apiOptions", "skipIfIsNotDirty", "width", "formName"]);
    const { formState: { submitCount, errors, isDirty }, } = methods;
    const { sendEvent } = data_tracking_context_1.useDataTrackingContext();
    const [processApiCall, mutationProps] = react_query_1.useMutation(onSubmit, apiOptions);
    const [isSubmitting, setIsSubmitting] = react_1.useState(false);
    const sendFormEvent = (event) => formName && sendEvent(event);
    const handleFormError = (error) => {
        const formErrorDetails = `${error.name}: ${error.message}`;
        sendFormEvent({ event: 'formError', formName, formErrorDetails });
    };
    const onFormSubmit = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        if (!isDirty && !!apiOptions.onSubmitWithoutChange) {
            apiOptions.onSubmitWithoutChange();
            sendFormEvent({ event: 'formSubmitSuccess', formName });
        }
        else if (apiOptions.skipMutation) {
            try {
                yield onSubmit(payload);
                sendFormEvent({ event: 'formSubmitSuccess', formName });
            }
            catch (error) {
                handleFormError(error);
            }
        }
        else {
            processApiCall(payload, {
                onSuccess: () => sendFormEvent({ event: 'formSubmitSuccess', formName }),
                onError: (error) => {
                    handleFormError(error);
                },
            });
        }
    });
    const handleFormSubmit = react_1.default.useCallback((e) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        if (e && e.preventDefault) {
            e.preventDefault();
            e.persist();
        }
        if (beforeValidation && (!skipIfIsNotDirty || isDirty)) {
            yield beforeValidation();
        }
        const actualSubmit = methods.handleSubmit(data => {
            sendFormEvent({ event: 'formSubmitAttempt', formName });
            return onFormSubmit(data);
        });
        return actualSubmit(e).finally(() => setIsSubmitting(false));
    }), [isDirty, formName, beforeValidation]);
    if (submitCount >= 1 && errors && Object.keys(errors).length > 0) {
        const formErrorDetails = JSON.stringify(methods.errors);
        sendFormEvent({ event: 'formError', formName, formErrorDetails });
    }
    react_1.useEffect(() => {
        sendFormEvent({ event: 'formStart', formName });
    }, [formName]);
    const formState = ['onTouched', 'onChange', 'onBlur'].includes((_b = props === null || props === void 0 ? void 0 : props.formOptions) === null || _b === void 0 ? void 0 : _b.mode)
        ? methods.formState
        : {
            isDirty: methods.formState.isDirty,
            dirtyFields: methods.formState.dirtyFields,
            isSubmitted: methods.formState.isSubmitted,
            isSubmitSuccessful: methods.formState.isSubmitSuccessful,
            submitCount: methods.formState.submitCount,
            touched: methods.formState.touched,
            isSubmitting: methods.formState.isSubmitting,
            isValidating: methods.formState.isValidating,
            errors: methods.formState.errors,
            isValid: Object.keys(methods.formState.errors).length > 0,
        };
    formState.isSubmitting = isSubmitting || formState.isSubmitting;
    return (react_1.default.createElement(components_library_1.Box, { width: width },
        react_1.default.createElement(react_hook_form_1.FormProvider, Object.assign({}, methods, props),
            react_1.default.createElement(ApiFormContext_1.ApiFormContextProvider, Object.assign({ formName: formName }, mutationProps),
                react_1.default.createElement("form", { onSubmit: handleFormSubmit, noValidate: true }, renderChildren(children, Object.assign(Object.assign({}, mutationProps), { formState })))))));
};
const ApiForm = (_a) => {
    var props = __rest(_a, []);
    const formMethods = react_hook_form_1.useFormContext();
    if (formMethods) {
        return react_1.default.createElement(WrappedApiForm, Object.assign({ formMethods: formMethods }, props));
    }
    else {
        return react_1.default.createElement(NotInitializedApiForm, Object.assign({}, props));
    }
};
exports.ApiForm = ApiForm;
