import React from 'react'
import { PageNotFound } from '@guiker/error-pages'
import { Route, Routes, Router as BaseRouter } from '@guiker/router'
import { routes } from '.'

type Props = {}

const Router: React.FC<Props> = () => {
  return (
    <BaseRouter routes={routes} unauthenticatedRedirectPath={routes.logIn.path}>
      <Routes>
        <Route {...routes.home} />
        <Route {...routes.listingLeads} />
        <Route {...routes.listing} />
        <Route {...routes.customCharge} />
        <Route {...routes.logIn} />
        <Route {...routes.payout} />
        <Route path='*' Component={PageNotFound} />
      </Routes>
    </BaseRouter>
  )
}

export { Router }
