"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveListingLeadDialog = void 0;
const react_1 = __importStar(require("react"));
const react_query_1 = require("@guiker/react-query");
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const RemoveListingLeadDialog = ({ listingLeadIdToRemove, setListingLeadToRemove, setApiErrorMsg }) => {
    const { t } = hooks_1.useTranslation(['common']);
    const [onProcessing, setOnProcessing] = react_1.useState(false);
    const apiClient = hooks_1.useListingLeadApiClient();
    const [removeOneListingLead, { isLoading: isLoadingRemove }] = react_query_1.useMutation(() => apiClient.removeOneListingLead({ pathParams: { listingLeadId: listingLeadIdToRemove } }), {
        onSuccess: () => {
            setOnProcessing(false);
            setListingLeadToRemove(null);
            // @todo: navigate back to list of listing leads
        },
        onError: () => {
            setApiErrorMsg(t('errors:removeListingLeadFailed'));
            setOnProcessing(false);
            setListingLeadToRemove(null);
        },
    });
    const handleRemoveListingLead = () => __awaiter(void 0, void 0, void 0, function* () {
        setOnProcessing(true);
        yield removeOneListingLead();
    });
    const disabled = onProcessing || isLoadingRemove;
    return (react_1.default.createElement(components_1.Dialog, { open: listingLeadIdToRemove !== null, onClose: () => setListingLeadToRemove(null) },
        react_1.default.createElement(components_1.Box, { padding: 3 },
            react_1.default.createElement(components_1.Grid, { container: true, direction: 'column', alignItems: 'center', justify: 'center' },
                react_1.default.createElement(components_1.Box, { mt: 3, width: 220, textAlign: 'center' },
                    react_1.default.createElement(components_1.PSmall, null, t('areYouSureToRemove'))),
                react_1.default.createElement(components_1.Box, { mt: 4 },
                    react_1.default.createElement(components_1.Grid, { container: true, justify: 'space-between', spacing: 2 },
                        react_1.default.createElement(components_1.Grid, { item: true, xs: 6 },
                            react_1.default.createElement(components_1.Button, { onClick: () => setListingLeadToRemove(null), disabled: disabled }, t('no'))),
                        react_1.default.createElement(components_1.Grid, { item: true, xs: 6 },
                            react_1.default.createElement(components_1.Button, { onClick: handleRemoveListingLead, disabled: disabled }, t('yes')))))))));
};
exports.RemoveListingLeadDialog = RemoveListingLeadDialog;
