import React from 'react'
import { AuthenticationContextProvider } from '@guiker/authentication-context'
import { ConfigContextProvider } from '@guiker/config-context'
import { DataTrackingContextProvider } from '@guiker/data-tracking-context'
import { I18nextProvider } from '@guiker/i18n'
import { ReactQueryConfigProvider } from '@guiker/react-query'
import { i18n } from './i18n'
import { Router } from './routes'
import { CssBaseline, LayoutContextProvider } from './components'
import { Page } from './components/Page'

const App: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <ConfigContextProvider>
        <ReactQueryConfigProvider config={{ queries: { refetchOnWindowFocus: false } }}>
          <DataTrackingContextProvider>
            <AuthenticationContextProvider>
              <LayoutContextProvider>
                <CssBaseline />
                <Page>
                  <Router />
                </Page>
              </LayoutContextProvider>
            </AuthenticationContextProvider>
          </DataTrackingContextProvider>
        </ReactQueryConfigProvider>
      </ConfigContextProvider>
    </I18nextProvider>
  )
}

export default App
