"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLegacyAppApiClient = void 0;
const legacy_app_api_client_1 = require("./legacy-app-api-client");
const config_context_1 = require("@guiker/config-context");
const __1 = require("..");
const useLegacyAppApiClient = () => {
    const { user } = __1.useAuthenticationContext();
    const { legacyApiUrl: baseUrl } = config_context_1.useConfig();
    return legacy_app_api_client_1.LegacyAppApiClient({ baseUrl, accessToken: user.accessToken });
};
exports.useLegacyAppApiClient = useLegacyAppApiClient;
