import { ListingLead, CustomCharge, Listing, Payout } from '../components'
import { Home, LogIn } from '../screens'

const routes = {
  home: {
    exact: true,
    path: '/',
    requireAuthentication: false,
    authenticationMethod: 'user',
    Component: Home,
  },
  listingLeads: {
    exact: false,
    path: '/listing-lead/*',
    requireAuthentication: true,
    authenticationMethod: 'user',
    Component: ListingLead,
    externalRoutes: {
      root: '/listing-lead',
    },
  },
  listing: {
    exact: false,
    path: 'listing/*',
    requireAuthentication: true,
    authenticationMethod: 'user',
    Component: Listing,
    externalRoutes: {
      root: '/listing',
    },
  },
  customCharge: {
    exact: false,
    path: '/custom-charge/*',
    requireAuthentication: true,
    authenticationMethod: 'user',
    Component: CustomCharge,
    externalRoutes: {
      root: '/custom-charge',
    },
  },
  logIn: {
    exact: true,
    path: '/log-in',
    requireAuthentication: false,
    Component: LogIn,
  },
  payout: {
    exact: false,
    path: 'payout/*',
    requireAuthentication: true,
    authenticationMethod: 'user',
    Component: Payout,
    externalRoutes: {
      root: '/payout',
    },
  },
} as const

export { routes }
