"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageSection = void 0;
const react_1 = __importStar(require("react"));
const i18n_1 = require("@guiker/i18n");
const react_hook_form_1 = require("@guiker/react-hook-form");
const form_1 = require("../../form");
const hooks_1 = require("../../hooks");
const __1 = require("..");
const StorageSection = () => {
    var _a;
    const { t } = i18n_1.useTranslation('common');
    const prefix = 'unit.storage';
    const { listingLead: { unit }, } = hooks_1.useListingLead();
    const storageIncluded = react_hook_form_1.useWatch({ name: `${prefix}.isIncluded` });
    const [withLocker, setWithLocker] = react_1.useState(storageIncluded === 'INCLUDED');
    const [lockerPossibleWithExtraFee, setLockerPossibleWithExtraFee] = react_1.useState(storageIncluded === 'CAN_INCLUDE_WITH_EXTRA_FEE');
    return (react_1.default.createElement(__1.Box, { mb: 3 },
        react_1.default.createElement(__1.H4, null, t('isThereALocker')),
        react_1.default.createElement(form_1.RadioGroup, { name: `${prefix}.isIncluded`, maxWidth: '100%', direction: 'row', defaultValue: ((_a = unit === null || unit === void 0 ? void 0 : unit.storage) === null || _a === void 0 ? void 0 : _a.isIncluded) || 'NOT_INCLUDED', gridContainerProps: { wrap: 'nowrap' }, options: [
                {
                    label: t('yesInclude'),
                    value: 'INCLUDED',
                    gridItemProps: {
                        sm: 4,
                    },
                    onChange: () => {
                        setWithLocker(true);
                        setLockerPossibleWithExtraFee(false);
                    },
                },
                {
                    label: t('no'),
                    value: 'NOT_INCLUDED',
                    gridItemProps: {
                        sm: 2,
                    },
                    onChange: () => {
                        setWithLocker(false);
                        setLockerPossibleWithExtraFee(false);
                    },
                },
                {
                    label: t('canIncludeForAnExtraFee'),
                    value: 'CAN_INCLUDE_WITH_EXTRA_FEE',
                    gridItemProps: {
                        sm: 5,
                    },
                    onChange: () => {
                        setWithLocker(false);
                        setLockerPossibleWithExtraFee(true);
                    },
                    disabled: withLocker,
                },
            ] }),
        react_1.default.createElement(__1.Grid, { container: true },
            react_1.default.createElement(__1.Grid, { item: true, xs: 12, sm: 6 },
                react_1.default.createElement(__1.Box, { hidden: !lockerPossibleWithExtraFee, mt: 2 },
                    react_1.default.createElement(form_1.TextField, { required: lockerPossibleWithExtraFee, name: `${prefix}.extraFee`, maxWidth: '100%', label: t('whatIsTheMonthlyFee') }))))));
};
exports.StorageSection = StorageSection;
