"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const core_1 = require("@material-ui/core");
const colors = {
    greenLight: '#5ECE58',
    green: '#00B96F',
    greenDark: '#00a462',
    blueLight: '#DBE9F9',
    blue: '#4A90E2',
    blueDark: '#1C3859',
    blueDarkBackground: '#313A45',
    red: '#E64D61',
    redLight: '#FFD3D9',
    greyBorder: '#CBCBCB',
    pink: '#FB5B91',
    yellow: '#F2D535',
    white: '#fff',
};
const muiTheme = core_1.createMuiTheme({
    palette: {
        background: {
            default: '#F8F9F9',
            darkBlue: colors.blueDarkBackground,
        },
        error: {
            light: colors.redLight,
            main: colors.red,
        },
        info: {
            light: colors.blueLight,
            dark: colors.blueDarkBackground,
            main: colors.blue,
        },
        primary: {
            contrastText: colors.white,
            dark: colors.greenDark,
            light: colors.greenLight,
            main: colors.green,
        },
        secondary: {
            main: colors.pink,
        },
        success: {
            contrastText: colors.white,
            main: colors.green,
        },
        text: {
            primary: '#4A4A4A',
            secondary: colors.blueDark,
        },
        warning: {
            contrastText: colors.white,
            main: colors.yellow,
        },
        grey: {
            400: colors.greyBorder,
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiButtonBase: {
            disableRipple: true,
        },
    },
    overrides: {
        MuiInputBase: {
            root: {
                backgroundColor: colors.white,
            },
        },
    },
    typography: {
        button: {
            textTransform: 'none',
            fontWeight: 'normal',
        },
        h6: {
            fontWeight: 'normal',
        },
    },
});
// TODO: do this properly, for now, I hope no one needs a 24 shadow lol
muiTheme.shadows[24] = '0px 3px 10px rgba(0, 0, 0, 0.08)';
const theme = Object.assign({}, core_1.responsiveFontSizes(muiTheme));
exports.theme = theme;
