"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListInvoicesScreen = void 0;
const react_1 = __importStar(require("react"));
const react_query_components_1 = require("@guiker/react-query-components");
const router_1 = require("@guiker/router");
const components_1 = require("../../components");
const hooks_1 = require("../../hooks");
const routes_1 = require("../../routes");
const invoice_columns_1 = require("./invoice-columns");
const ListInvoicesScreen = () => {
    const { t } = hooks_1.useTranslation(['listInvoicesScreen']);
    const { pathname } = router_1.useLocation();
    const navigate = router_1.useNavigate();
    const [invoiceIdToCancel, setInvoiceIdToCancel] = react_1.useState();
    const apiClient = hooks_1.usePaymentApiClient();
    const queryCache = hooks_1.useQueryCache();
    const fetcher = (paginate) => apiClient.readAllInvoicesByAdmin({ queryParams: paginate }, { includeMetadata: true });
    const paginateCacheKey = 'readAllInvoicesByAdmin';
    const [cancelInvoice, { isLoading }] = hooks_1.useMutation((invoiceId) => apiClient.cancelOneInvoice({ pathParams: { invoiceId } }), {
        onSuccess: () => {
            queryCache.refetchQueries(paginateCacheKey);
            setInvoiceIdToCancel(null);
        },
    });
    const closeCancelDialog = () => setInvoiceIdToCancel(null);
    return (react_1.default.createElement(components_1.BaseScreen, { title: t('chargeHistory'), hasDivider: false, maxWidth: '100%' },
        react_1.default.createElement(components_1.Box, { my: 4 },
            react_1.default.createElement(components_1.Button, { onClick: () => navigate(`${pathname}${routes_1.routes.create.path}`) }, "Create Invoice")),
        react_1.default.createElement(react_query_components_1.PaginatedTable, { queryKey: paginateCacheKey, fetcher: fetcher, columns: invoice_columns_1.getInvoiceColumns(setInvoiceIdToCancel) }),
        react_1.default.createElement(components_1.ConfirmDialog, { open: !!invoiceIdToCancel, title: t('areYouSure'), description: t('cancelInvoiceDescription'), cancelButtonProps: { children: t('no'), onClick: closeCancelDialog }, confirmButtonProps: {
                children: t('yes'),
                isLoading: isLoading,
                onClick: () => cancelInvoice(invoiceIdToCancel),
            } })));
};
exports.ListInvoicesScreen = ListInvoicesScreen;
