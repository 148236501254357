"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Asset = void 0;
const react_1 = __importStar(require("react"));
const clsx_1 = require("@guiker/clsx");
const components_core_1 = require("@guiker/components-core");
const icons_1 = require("@guiker/icons");
const utils_1 = require("../../utils");
const ViewAsset_1 = require("../ViewAsset");
const __1 = require("../");
const AssetImage_1 = require("../AssetImage");
const lodash_1 = require("@guiker/lodash");
const THUMBNAIL_RATIO = 1.61;
const DEFAULT_MAX_WIDTH = 240;
const useStyle = components_core_1.makeStyles({
    root: {
        maxWidth: ({ maxWidth }) => maxWidth,
    },
    thumbnail: {
        maxHeight: ({ maxWidth }) => (lodash_1.isNumber(maxWidth) ? maxWidth / THUMBNAIL_RATIO : 'default'),
        height: ({ maxWidth }) => (lodash_1.isNumber(maxWidth) ? maxWidth / THUMBNAIL_RATIO : 'default'),
        borderRadius: 8,
        cursor: 'pointer',
        overflow: 'hidden',
        display: 'flex',
    },
    assetButton: {
        flexGrow: 1,
        height: 40,
        justifyContent: 'flex-start',
    },
    truncatedText: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    deleteButton: {
        height: 40,
        minWidth: 40,
        marginLeft: components_core_1.theme.spacing(1),
        '&:active': {
            background: components_core_1.theme.palette.grey[10],
        },
    },
});
const Asset = ({ asset, maxWidth = DEFAULT_MAX_WIDTH, onRemoveAsset, readOnly, withThumbnail, }) => {
    const classes = useStyle({ maxWidth });
    const [open, setOpen] = react_1.useState(false);
    const handleViewAsset = () => {
        if (!utils_1.isImageType(asset.mimeType)) {
            window.open(asset.presignedDownloadUrl);
        }
        else {
            setOpen(!open);
        }
    };
    return (react_1.default.createElement(__1.Box, { className: classes.root },
        withThumbnail && utils_1.isImageType(asset.mimeType) && (react_1.default.createElement(__1.Box, { mb: 1, onClick: handleViewAsset, className: classes.thumbnail },
            react_1.default.createElement(AssetImage_1.AssetImage, { asset: asset }))),
        react_1.default.createElement(__1.Box, { display: 'flex' },
            react_1.default.createElement(__1.MiniButton, { startIcon: react_1.default.createElement(icons_1.PaperclipSmallIcon, { color: 'textPrimary' }), color: 'textPrimary', className: classes.assetButton, onClick: handleViewAsset },
                react_1.default.createElement(__1.P, { mb: 0, className: classes.truncatedText }, asset.fileName)),
            !readOnly && (react_1.default.createElement(__1.MiniButton, { className: clsx_1.clsx(classes.deleteButton), onClick: onRemoveAsset, startIcon: react_1.default.createElement(icons_1.DeleteMiniIcon, { color: 30 }) }))),
        react_1.default.createElement(ViewAsset_1.ViewAsset, { readOnly: readOnly, asset: asset, open: open, onRemoveAsset: onRemoveAsset, onClose: handleViewAsset })));
};
exports.Asset = Asset;
