import React from 'react'
import { AppBar as BaseAppBar } from '@guiker/components-library'
import { NotesTasksIcon, LogOutIcon } from '@guiker/icons'
import { useAuthenticationContext, useTranslation } from '../../hooks'
import { routes } from '../../routes'

export type AppBarProps = {}

const AppBar: React.FC<AppBarProps> = () => {
  const { user, unsetUser } = useAuthenticationContext()
  const { t } = useTranslation()

  const primaryNavigation = user ? [] : [{ text: t('login'), to: routes.logIn.path }]
  const secondaryNavigation = user
    ? [
        { text: t('common:navbar:listing'), icon: <NotesTasksIcon />, to: routes.listing.externalRoutes.root },
        {
          text: t('common:navbar:listingLeads'),
          icon: <NotesTasksIcon />,
          to: routes.listingLeads.externalRoutes.root,
        },
        {
          text: t('common:navbar:payout'),
          icon: <NotesTasksIcon />,
          to: routes.payout.externalRoutes.root,
        },
        {
          text: t('common:navbar:customCharge'),
          icon: <NotesTasksIcon />,
          to: routes.customCharge.externalRoutes.root,
        },
      ]
    : []
  const tertiaryNavigation = user ? [{ text: t('logout'), icon: <LogOutIcon />, onClick: unsetUser }] : []

  return (
    <BaseAppBar
      subTitle={t('office')}
      user={user}
      primaryNavigation={primaryNavigation}
      secondaryNavigation={secondaryNavigation}
      tertiaryNavigation={tertiaryNavigation}
    />
  )
}

export { AppBar }
