"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getS3ApiClient = exports.buildS3FormDataUpload = void 0;
const base_rest_client_1 = require("@guiker/base-rest-client");
const lodash_1 = require("@guiker/lodash");
const buildS3FormDataUpload = ({ file, s3Info }) => {
    const formData = new FormData();
    formData.set('key', s3Info.s3Key);
    formData.set('acl', s3Info.acl);
    formData.set('Content-Type', file.type);
    formData.set('Content-Disposition', `attachment; filename=${lodash_1.sanitizeFileName(file.name)}`);
    formData.set('X-Amz-Credential', s3Info.amzCredential);
    formData.set('X-Amz-Algorithm', s3Info.amzAlgorithm);
    formData.set('X-Amz-Date', s3Info.amzDate);
    formData.set('X-Amz-Signature', s3Info.signature);
    formData.set('Policy', s3Info.policy);
    formData.set('X-Amz-Security-Token', s3Info.amzSecurityToken);
    formData.set('file', file);
    return formData;
};
exports.buildS3FormDataUpload = buildS3FormDataUpload;
const getS3ApiClient = () => {
    const restClient = new base_rest_client_1.BaseRestClient(console);
    return {
        uploadFile: ({ url, file, s3Info }) => {
            const formData = exports.buildS3FormDataUpload({ file, s3Info });
            return restClient.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        },
    };
};
exports.getS3ApiClient = getS3ApiClient;
