"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListLegacyLeasesScreen = void 0;
const react_1 = __importStar(require("react"));
const listing_entity_1 = require("@guiker/listing-entity");
const listing_context_1 = require("@guiker/listing-context");
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const lease_columns_1 = require("./lease-columns");
const ListLegacyLeasesScreen = () => {
    const { t } = hooks_1.useTranslation(['listLegacyLeasesScreen']);
    const listingApiClient = listing_context_1.useListingApiClient();
    const [listing, setListing] = react_1.useState(undefined);
    const listingOwner = listing ? listing.listingUsers.find(lu => lu.role === 'OWNER').user : undefined;
    const [currentListingId, setCurrentListingId] = react_1.useState(undefined);
    const onSubmit = ({ listingId }) => __awaiter(void 0, void 0, void 0, function* () {
        if (listingId && !isNaN(Number(listingId))) {
            setCurrentListingId(listingId);
            setListing(yield listingApiClient.readOneListing({ pathParams: { listingId } }));
        }
    });
    const paginateCacheKey = 'readEffectiveLeases';
    const queryCache = hooks_1.useQueryCache();
    const apiClient = hooks_1.useLegacyAppApiClient();
    const fetcher = ({ page, perPage, searchString }) => {
        const listingId = searchString && !isNaN(Number(searchString)) ? Number(searchString) : undefined;
        if (listingId) {
            return apiClient.readEffectiveLeasesByListingId({ listingId, page, perPage });
        }
    };
    const [leaseIdToTerminate, setLeaseIdToTerminate] = react_1.useState();
    const closeTerminateLeaseDialog = () => setLeaseIdToTerminate(undefined);
    const [terminateLease, { isLoading: isLoadingTerminateLease }] = hooks_1.useMutation((leaseId) => apiClient.terminateLease({ leaseId }), {
        onSuccess: () => {
            queryCache.refetchQueries(paginateCacheKey);
            setLeaseIdToTerminate(undefined);
        },
        onError: () => {
            setLeaseIdToTerminate(undefined);
        },
    });
    return (react_1.default.createElement(components_1.BaseScreen, { title: t('offlineRentAdjustment'), hasDivider: false, maxWidth: 1400 },
        react_1.default.createElement(components_1.ApiForm, { onSubmit: onSubmit },
            react_1.default.createElement(components_1.Box, { display: 'flex', my: 4 },
                react_1.default.createElement(components_1.TextField, { name: 'listingId', placeholder: t('searchByListingId'), defaultValue: '', startAdornment: react_1.default.createElement(components_1.Box, { mr: 2 },
                        react_1.default.createElement(components_1.SearchIcon, null)) }),
                react_1.default.createElement(components_1.Box, { ml: 2 },
                    react_1.default.createElement(components_1.Button, { type: 'submit' }, t('search')))),
            listing && listingOwner && (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(components_1.P, null, listing_entity_1.address(listing).printFullAddress()),
                react_1.default.createElement(components_1.P, null, `${[listingOwner.firstName, listingOwner.lastName, listingOwner.email].join(' ')}`))),
            react_1.default.createElement(components_1.PaginatedTable, { queryKey: paginateCacheKey, fetcher: fetcher, columns: lease_columns_1.getLeaseColumns(setLeaseIdToTerminate), pagination: { searchString: currentListingId } }),
            react_1.default.createElement(components_1.ConfirmDialog, { open: !!leaseIdToTerminate, title: t('terminateLease'), description: t('terminateLeaseDescription'), cancelButtonProps: { children: t('cancel'), onClick: closeTerminateLeaseDialog }, confirmButtonProps: {
                    color: 'alert',
                    children: t('terminate'),
                    isLoading: isLoadingTerminateLease,
                    onClick: () => terminateLease(leaseIdToTerminate),
                } }))));
};
exports.ListLegacyLeasesScreen = ListLegacyLeasesScreen;
