"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkAssetUploaderContextProvider = void 0;
const react_1 = __importStar(require("react"));
const react_context_1 = require("@guiker/react-context");
const react_query_1 = require("@guiker/react-query");
const react_hook_form_1 = require("react-hook-form");
const BulkAssetUploaderContext_1 = require("../../context/BulkAssetUploaderContext");
const hooks_1 = require("../../hooks");
const utils_1 = require("../../utils");
const buildFlattenedAsset = (assetGroups) => assetGroups === null || assetGroups === void 0 ? void 0 : assetGroups.reduce((accu, group) => [
    ...accu,
    ...group.assets.map((asset, index) => (Object.assign(Object.assign({}, asset), { groupIndex: index, groupType: group.type }))),
], []);
const BulkAssetUploaderContextProvider = ({ children, scope, assetGroups: assetGroupsProps = [], }) => {
    const apiClient = hooks_1.useAssetApiClient();
    const s3ApiClient = utils_1.getS3ApiClient();
    const formMethods = react_hook_form_1.useFormContext();
    const [uploadFile, { isLoading: isUploading }] = react_query_1.useMutation(s3ApiClient.uploadFile);
    const [assetGroups, setAssetGroups] = react_1.useState(assetGroupsProps);
    const [bulkUpdate] = react_query_1.useMutation(() => __awaiter(void 0, void 0, void 0, function* () {
        const flattenedAssets = buildFlattenedAsset(assetGroups);
        const response = yield apiClient.bulkUpdate({
            pathParams: { scopeType: scope.type, scopeId: scope.id },
            payload: flattenedAssets === null || flattenedAssets === void 0 ? void 0 : flattenedAssets.map(({ id, fileName, mimeType, size }) => {
                return { id, fileName, mimeType, size, _delete: false };
            }),
        });
        const updatedAssetGroups = yield Promise.all(response.items.map((item, index) => __awaiter(void 0, void 0, void 0, function* () {
            if (item.status !== 'success') {
                return;
            }
            const _a = item.data.details, { url } = _a, s3Info = __rest(_a, ["url"]);
            const assetFile = flattenedAssets && flattenedAssets[index];
            const assetGroup = assetGroups.find(assetGroup => assetGroup.type === assetFile.groupType);
            if (!url || !(assetFile === null || assetFile === void 0 ? void 0 : assetFile.file)) {
                return assetGroup;
            }
            if (formMethods) {
                formMethods.setValue(`${assetFile.name}.id`, item.data.id);
            }
            yield uploadFile({ url, file: assetFile.file, s3Info: s3Info });
            const presignedDownloadUrl = assetFile.presignedDownloadUrl;
            assetGroup.assets.splice(assetFile.groupIndex, 1, Object.assign(Object.assign({}, item.data), { presignedDownloadUrl }));
            return assetGroup;
        })));
        setAssetGroups([...updatedAssetGroups]);
    }));
    const removeAsset = (type, index) => {
        const groupIndex = assetGroups.findIndex(assetGroup => assetGroup.type === type);
        if (groupIndex !== -1) {
            assetGroups[groupIndex].assets.splice(index, 1);
        }
        setAssetGroups([...assetGroups]);
    };
    const addAssets = (type, assets) => {
        const index = assetGroups.findIndex(assetGroup => (assetGroup === null || assetGroup === void 0 ? void 0 : assetGroup.type) === type);
        if (index !== -1) {
            assetGroups[index] = Object.assign(Object.assign({}, assetGroups[index]), { assets: [...assetGroups[index].assets, ...assets] });
        }
        else {
            assetGroups.push({
                type,
                assets,
            });
        }
        setAssetGroups([...assetGroups]);
    };
    const value = { assetGroups, removeAsset, addAssets, bulkUpdate, isUploading };
    return (react_1.default.createElement(BulkAssetUploaderContext_1.BulkAssetUploaderContext.Provider, { value: value }, react_context_1.renderChildren(children, value)));
};
exports.BulkAssetUploaderContextProvider = BulkAssetUploaderContextProvider;
