"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routes = void 0;
const screens_1 = require("../screens");
const routes = {
    list: {
        exact: true,
        path: '/',
        Component: screens_1.ListInvoicesScreen,
        requireAuthentication: true,
        authenticationMethod: 'user',
    },
    create: {
        exact: true,
        path: '/create',
        Component: screens_1.CustomChargeCreateScreen,
        requireAuthentication: true,
        authenticationMethod: 'user',
    },
};
exports.routes = routes;
