"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListingApiClient = void 0;
const listing_api_client_1 = require("@guiker/listing-api-client");
const config_context_1 = require("@guiker/config-context");
const authentication_context_1 = require("@guiker/authentication-context");
const uuid_1 = require("@guiker/uuid");
const useListingApiClient = () => {
    const { apiBaseUrl: baseUrl, debug } = config_context_1.useConfig();
    const { user: { accessToken }, } = authentication_context_1.useAuthenticationContext();
    return listing_api_client_1.ApiClient({
        accessToken,
        baseUrl,
        correlatedRequestId: uuid_1.v4(),
        logger: debug ? console : undefined,
    });
};
exports.useListingApiClient = useListingApiClient;
