"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Step2 = void 0;
const react_1 = __importStar(require("react"));
const components_core_1 = require("@guiker/components-core");
const react_hook_form_1 = require("@guiker/react-hook-form");
const payment_entity_1 = require("@guiker/payment-entity");
const tax_1 = require("@guiker/tax");
const money_1 = require("@guiker/money");
const payment_app_components_1 = require("@guiker/payment-app-components");
const Step_1 = require("../../common/Step");
const hooks_1 = require("../../../hooks");
const step_header_1 = require("../step-header");
const __1 = require("../..");
const useStyles = components_core_1.makeStyles(() => ({
    invoiceItemField: {
        marginBottom: components_core_1.theme.spacing(1),
    },
}));
const FormContent = ({ onClickBack, onClickNext }) => {
    var _a, _b;
    const { t } = hooks_1.useTranslation();
    const classes = useStyles();
    const { invoice, setInvoice, taxes } = hooks_1.useAppContext();
    const currency = money_1.currency[invoice.currency];
    const taxId = react_hook_form_1.useWatch({ name: `taxId`, defaultValue: (_a = taxes[0]) === null || _a === void 0 ? void 0 : _a.taxId });
    const [tax, setTax] = react_1.useState(taxes.find(t => t.taxId === taxId));
    const [nonTaxableAmount, setNonTaxableAmount] = react_1.useState(0);
    const [taxableAmount, setTaxableAmount] = react_1.useState(0);
    const [totalAmount, setTotalAmount] = react_1.useState({ subTotal: 0, tax: 0, final: 0 });
    const taxInfo = react_1.useMemo(() => ({
        taxPercentage: `${tax_1.fromTax(tax).toPercentage()}`,
        taxTypes: tax ? `(${tax.rates.map(r => r.type).join(' + ')})` : '',
    }), [tax]);
    const validateNumberInput = (value) => {
        const isPositiveNumber = value !== '' && value !== null && Math.sign(Number(value)) >= 0;
        return isPositiveNumber ? Number(value) : 0;
    };
    const setAmountState = (value, setAmount) => {
        setAmount(money_1.money.toAmount(validateNumberInput(value), currency).amount);
    };
    react_1.useEffect(() => {
        if (!tax || tax.taxId !== taxId) {
            setTax(taxes.find(t => t.taxId === taxId));
        }
    }, [tax, taxId]);
    react_1.useEffect(() => {
        const taxAmount = tax_1.computeTaxAmount(taxableAmount, tax);
        const subTotal = nonTaxableAmount + taxableAmount + taxAmount;
        setTotalAmount({
            subTotal,
            tax: taxAmount,
            final: subTotal * (1 + payment_entity_1.CREDIT_CARD_TRANSACTION_RATE),
        });
    }, [tax, nonTaxableAmount, taxableAmount]);
    const onClickStep2Next = () => {
        setInvoice(Object.assign(Object.assign({}, invoice), { items: [
                {
                    label: 'Non-taxable amount',
                    pricePerUnit: nonTaxableAmount,
                },
                {
                    label: 'Taxable amount',
                    pricePerUnit: taxableAmount,
                    taxes: [{ taxId }],
                },
            ] }));
        onClickNext();
    };
    return (react_1.default.createElement(__1.BaseScreen, { title: t('chargeAmount') },
        react_1.default.createElement(__1.TextField, { className: classes.invoiceItemField, name: 'nonTaxableAmount', label: t('nonTaxableAmountLabel'), defaultValue: money_1.money.fromAmount(nonTaxableAmount, currency).monetized, onChange: e => setAmountState(e.target.value, setNonTaxableAmount) }),
        react_1.default.createElement(__1.P, { color: 50, mb: 3 }, t('nonTaxableAmountDescription')),
        react_1.default.createElement(__1.TextField, { className: classes.invoiceItemField, name: 'taxableAmount', label: t('taxableAmountLabel'), defaultValue: money_1.money.fromAmount(taxableAmount, currency).monetized, onChange: e => setAmountState(e.target.value, setTaxableAmount) }),
        react_1.default.createElement(__1.P, { color: 50, mb: 3 }, t('taxableAmountDescription')),
        react_1.default.createElement(__1.Box, { mb: 4 },
            react_1.default.createElement(__1.PBold, null, t('provinceCustomerIsIn')),
            react_1.default.createElement(__1.RadioGroup, { name: `taxId`, direction: 'row', defaultValue: (_b = taxes[0]) === null || _b === void 0 ? void 0 : _b.taxId, options: taxes.map(({ taxId, region }) => ({
                    label: t(`common:tax:${region.key}`),
                    value: taxId,
                })) })),
        react_1.default.createElement(payment_app_components_1.Payment.Breakdown, { items: [
                {
                    label: t('nonTaxableAmount'),
                    price: money_1.money.fromAmount(nonTaxableAmount, currency).toString(true),
                },
                {
                    label: t('taxableAmount'),
                    price: money_1.money.fromAmount(taxableAmount, currency).toString(true),
                },
                {
                    color: 30,
                    label: t('taxes', taxInfo),
                    price: money_1.money.fromAmount(totalAmount.tax, currency).toString(true),
                },
                {
                    label: t('processingFee', { transactionFeePercentage: `${payment_entity_1.CREDIT_CARD_TRANSACTION_RATE * 100}%` }),
                    price: money_1.money.fromAmount(totalAmount.subTotal * payment_entity_1.CREDIT_CARD_TRANSACTION_RATE, currency).toString(true),
                },
            ], totalPrice: money_1.money.fromAmount(totalAmount.final, currency).toString(true) }),
        react_1.default.createElement(__1.Box, { display: 'flex', justifyContent: 'flex-end', mt: 6, mb: 4 },
            react_1.default.createElement(__1.Box, { mr: 3 },
                react_1.default.createElement(__1.SecondaryButton, { onClick: onClickBack }, t('back'))),
            react_1.default.createElement(__1.Button, { onClick: onClickStep2Next, disabled: totalAmount.final <= 0 }, t('next')))));
};
const Step2 = props => {
    var _a;
    const { invoice } = hooks_1.useAppContext();
    if (!((_a = invoice === null || invoice === void 0 ? void 0 : invoice.customer) === null || _a === void 0 ? void 0 : _a.id)) {
        props.onClickBack();
    }
    return (react_1.default.createElement(Step_1.Step, Object.assign({ isSubmitting: false, formOptions: { defaultValues: invoice || {} } }, props),
        react_1.default.createElement(step_header_1.StepHeader, { onClick: props.onClickBack }),
        react_1.default.createElement(FormContent, Object.assign({}, props))));
};
exports.Step2 = Step2;
