"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadScript = void 0;
const registeredScript = {};
function loadScript(id, src, cb) {
    return __awaiter(this, void 0, void 0, function* () {
        if (registeredScript[id]) {
            cb();
        }
        else {
            registeredScript[id] = true;
            const script = 'script';
            const refScript = document.getElementsByTagName(script)[0];
            const newScript = document.createElement(script);
            newScript.src = src;
            if (refScript && refScript.parentNode) {
                refScript.parentNode.insertBefore(newScript, refScript);
            }
            else {
                document.head.appendChild(newScript);
            }
            newScript.onload = cb;
        }
    });
}
exports.loadScript = loadScript;
