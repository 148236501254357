"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PasswordTextField = void 0;
const react_1 = __importStar(require("react"));
const rhf_components_1 = require("@guiker/rhf-components");
const i18n_1 = require("@guiker/i18n");
const components_1 = require("../components");
const useStyles = components_1.makeStyles({
    password: {
        fontWeight: 300,
        width: components_1.theme.spacing(6),
        paddingLeft: components_1.theme.spacing(1),
        margin: 0,
        cursor: 'pointer',
    },
});
const PasswordTextField = ({ label, name = 'password', required = true, disabled }) => {
    const { t } = i18n_1.useTranslation('passwordTextField');
    const classes = useStyles();
    const [showPassword, setShowPassword] = react_1.useState(false);
    const handleShowPasswordClick = () => setShowPassword(!showPassword);
    return (react_1.default.createElement(rhf_components_1.TextField, { label: label || t('password'), name: name, type: showPassword ? 'text' : 'password', disabled: disabled, required: required, endAdornment: react_1.default.createElement(components_1.InputAdornment, { onClick: handleShowPasswordClick, position: 'end' },
            react_1.default.createElement(components_1.Box, { className: classes.password }, showPassword ? t('hide') : t('show'))) }));
};
exports.PasswordTextField = PasswordTextField;
