"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateServiceAgreementScreen = void 0;
const react_1 = __importStar(require("react"));
const react_query_1 = require("@guiker/react-query");
const context_1 = require("../../context");
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const routes_1 = require("../../routes");
const helper_1 = require("../../helper");
const CreateServiceAgreementForm_1 = require("./CreateServiceAgreementForm");
const Content = ({ listing }) => {
    const { user } = hooks_1.useAuthenticationContext();
    const apiClient = hooks_1.useServiceAgreementAdminApiClient();
    const { setServiceAgreement, serviceAgreementId, setEditMode } = hooks_1.useServiceAgreementContext();
    const serviceAgreementPayload = helper_1.buildServiceAgreementPayload(user, listing);
    const [createServiceAgreementFromListing, { isLoading: isCreating, error: createError }] = hooks_1.useMutation(() => {
        return apiClient.createServiceAgreement({ payload: serviceAgreementPayload });
    }, {
        onSuccess: serviceAgreement => setServiceAgreement(serviceAgreement),
    });
    const { isLoading: isFetching } = react_query_1.useQuery(['serviceAgreementFromListing', listing === null || listing === void 0 ? void 0 : listing.externalId], (_, id) => apiClient.readOneByScopeOrInitialize({ pathParams: { scopeType: 'listing', scopeId: id } }), {
        retry: false,
        onSuccess: serviceAgreement => serviceAgreement.id ? setServiceAgreement(serviceAgreement) : createServiceAgreementFromListing(),
    });
    const loading = isCreating || isFetching;
    const error = createError;
    react_1.useEffect(() => {
        setEditMode(context_1.EditModes.FromListing);
    }, [setEditMode]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(components_1.FeedbackComponent, { loading: loading, toasterMessage: error === null || error === void 0 ? void 0 : error.message }),
        !loading && !!serviceAgreementId && react_1.default.createElement(CreateServiceAgreementForm_1.CreateServiceAgreementForm, null)));
};
const CreateServiceAgreementScreen = () => {
    const { listing, listingId } = hooks_1.useListingContext();
    const navigate = hooks_1.useNavigate();
    return (react_1.default.createElement(context_1.ServiceAgreementContextProvider, { listingId: listingId },
        react_1.default.createElement(components_1.Page, { onClose: () => navigate(`/listing/${routes_1.routes.listServiceAgreements.path}`) },
            react_1.default.createElement(Content, { listing: listing }))));
};
exports.CreateServiceAgreementScreen = CreateServiceAgreementScreen;
