"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviveListing = void 0;
const react_1 = __importStar(require("react"));
const react_query_1 = require("@guiker/react-query");
const react_hook_form_1 = require("@guiker/react-hook-form");
const rhf_components_1 = require("@guiker/rhf-components");
const listing_api_definition_1 = require("@guiker/listing-api-definition");
const listing_context_1 = require("@guiker/listing-context");
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const useStyles = components_1.makeStyles({
    container: {
        padding: components_1.theme.spacing(3),
        height: '90vh',
    },
});
const FormContent = () => {
    const { t } = hooks_1.useTranslation();
    const { isProcessing } = components_1.useApiFormContext();
    return (react_1.default.createElement(components_1.Box, null,
        react_1.default.createElement(rhf_components_1.TextField, { name: 'listingId', label: t('common:listing:listingId') }),
        react_1.default.createElement(components_1.ButtonWithLoader, { type: 'submit', disabled: isProcessing, isLoading: isProcessing }, t('common:listing:revive'))));
};
const ReviveListing = () => {
    const classes = useStyles();
    const apiClient = listing_context_1.useListingApiClient();
    const [reviveListing] = react_query_1.useMutation((listingId) => apiClient.reviveListing({ pathParams: { listingId } }));
    const resolver = react_hook_form_1.yupResolver(listing_api_definition_1.listingIdNested);
    const [listing, setListing] = react_1.useState();
    const onSubmit = ({ listingId }) => __awaiter(void 0, void 0, void 0, function* () {
        return reviveListing(listingId, {
            onSuccess: (listing) => setListing(listing),
        });
    });
    return (react_1.default.createElement(components_1.NarrowContent, null,
        react_1.default.createElement(components_1.ApiForm, { onSubmit: onSubmit, formOptions: { resolver } },
            react_1.default.createElement(components_1.Box, { className: classes.container },
                react_1.default.createElement(FormContent, null),
                react_1.default.createElement(components_1.P, { component: 'div', mt: 3 },
                    react_1.default.createElement("pre", null, listing ? JSON.stringify(listing, null, 2) : ''))))));
};
exports.ReviveListing = ReviveListing;
