"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildServiceAgreementPayload = void 0;
const date_1 = require("@guiker/date");
const i18n_1 = require("@guiker/i18n");
const listing_entity_1 = require("@guiker/listing-entity");
const service_agreement_entity_1 = require("@guiker/service-agreement-entity");
const buildServiceAgreementPayload = (user, listing) => {
    const { t } = i18n_1.useTranslation(['createServiceAgreement']);
    const listingFullAddress = listing_entity_1.address(listing).printFullAddress();
    return {
        type: service_agreement_entity_1.ServiceAgreementTypes.tenantFinder,
        scope: {
            type: 'Listing',
            id: listing.externalId,
        },
        creator: {
            userId: user.id,
            emailAddress: user.emailAddress,
        },
        sections: {
            clientName: {
                label: t('clientNameLabel'),
                clientName: null,
            },
            objective: {
                label: t('objectiveLabel'),
                address: listingFullAddress,
            },
            guikerServices: {
                label: t('guikerServicesLabel'),
                clause: t('guikerServicesClause'),
            },
            clientAgreesTo: {
                label: t('clientAgreesToLabel'),
                clause: t('clientAgreesToClause'),
            },
            term: {
                label: t('termLabel'),
                clause: t('termClause'),
            },
            fees: {
                label: t('feesLabel'),
                subText: t('feesSubtext'),
                clause: t('feesClause', {
                    firstYearLeaseFee: 2,
                }),
                firstYearLeaseFee: 2,
            },
            guikerSignature: {
                content: 'Nan Hao',
                date: date_1.DateTime.local().toString(),
                title: t('guikerSignatureTitle'),
            },
        },
    };
};
exports.buildServiceAgreementPayload = buildServiceAgreementPayload;
