"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayoutMethodsContainer = void 0;
const react_1 = __importStar(require("react"));
const payout_context_1 = require("@guiker/payout-context");
const react_query_1 = require("@guiker/react-query");
const i18n_1 = require("../../i18n");
const components_1 = require("../../components");
const get_payout_methods_columns_1 = require("./get-payout-methods-columns");
const PayoutMethodsContainer = () => {
    const { t } = i18n_1.useTranslation(['office-payout']);
    const adminApiClient = payout_context_1.useAdminPayoutApiClient();
    const [searchString, setSearchString] = react_1.useState('');
    const queryCache = react_query_1.useQueryCache();
    const fetcher = (paginate) => adminApiClient.readAllPayoutMethods({ queryParams: Object.assign({}, paginate) }, { includeMetadata: true });
    const handleSearch = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setSearchString(data.searchInput);
        yield queryCache.refetchQueries('readAllPayoutMethods');
    });
    return (react_1.default.createElement(components_1.Box, { mt: 2 },
        react_1.default.createElement(components_1.ApiForm, { onSubmit: handleSearch },
            react_1.default.createElement(components_1.TextField, { name: 'searchInput', label: t('listPayoutMethodScreen.searchInputLabel'), maxWidth: 328 }),
            react_1.default.createElement(components_1.Box, { mt: 4 },
                react_1.default.createElement(components_1.PaginatedTable, { queryKey: 'readAllPayoutMethods', fetcher: fetcher, columns: get_payout_methods_columns_1.getPayoutMethodColumns(), pagination: { searchString } })))));
};
exports.PayoutMethodsContainer = PayoutMethodsContainer;
