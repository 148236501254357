"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppContext = void 0;
const react_1 = require("react");
const AppContext_1 = require("../context/AppContext");
const useAppContext = () => {
    const context = react_1.useContext(AppContext_1.AppContext);
    if (context === undefined) {
        throw new Error('useAppContext can only be used inside AppContextProvider');
    }
    return context;
};
exports.useAppContext = useAppContext;
