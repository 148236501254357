"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewLeasePaymentHistoryScreen = void 0;
const react_1 = __importStar(require("react"));
const router_1 = require("@guiker/router");
const date_1 = require("@guiker/date");
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const lease_payment_history_columns_1 = require("./lease-payment-history-columns");
const ViewLeasePaymentHistoryScreen = () => {
    const { t } = hooks_1.useTranslation(['viewLeasePaymentHistoryScreen']);
    const { leaseId } = router_1.useParams();
    const [instalmentToMarkPaidOffline, setInstalmentToMarkPaidOffline] = react_1.useState();
    const [instalmentToUndoPaidOffline, setInstalmentToUndoPaidOffline] = react_1.useState();
    const closeMarkPaidOfflineDialog = () => setInstalmentToMarkPaidOffline(undefined);
    const closeUndoPaidOfflineDialog = () => setInstalmentToUndoPaidOffline(undefined);
    const paginateCacheKey = 'readLeasePaymentHistory';
    const queryCache = hooks_1.useQueryCache();
    const apiClient = hooks_1.useLegacyAppApiClient();
    const fetcher = ({ page, perPage, searchString }) => {
        const currentLeaseId = searchString && !isNaN(Number(searchString)) ? Number(searchString) : undefined;
        if (currentLeaseId) {
            return apiClient.readLeasePaymentHistory({ leaseId: currentLeaseId, page, perPage });
        }
    };
    const [markInstalmentPaidOffline, { isLoading: isLoadingMarkPaidOffline }] = hooks_1.useMutation((instalmentId) => apiClient.createTransactionForOfflinePaid({ instalmentId }), {
        onSuccess: () => {
            queryCache.refetchQueries(paginateCacheKey);
            setInstalmentToMarkPaidOffline(undefined);
        },
        onError: () => {
            setInstalmentToMarkPaidOffline(undefined);
        },
    });
    const [undoInstalmentPaidOffline, { isLoading: isLoadingUndoPaidOffline }] = hooks_1.useMutation((instalmentId) => apiClient.deleteTransactionForOfflinePaid({ instalmentId }), {
        onSuccess: () => {
            queryCache.refetchQueries(paginateCacheKey);
            setInstalmentToUndoPaidOffline(undefined);
        },
        onError: () => {
            setInstalmentToUndoPaidOffline(undefined);
        },
    });
    return (react_1.default.createElement(components_1.BaseScreen, { title: t('offlineRentAdjustment'), hasDivider: false, maxWidth: 1400 },
        react_1.default.createElement(components_1.Link, { to: '../' }, t('backToLeaseList')),
        react_1.default.createElement(components_1.PaginatedTable, { queryKey: paginateCacheKey, fetcher: fetcher, columns: lease_payment_history_columns_1.getLeasePaymentHistoryColumns(setInstalmentToMarkPaidOffline, setInstalmentToUndoPaidOffline), pagination: { searchString: leaseId } }),
        react_1.default.createElement(components_1.ConfirmDialog, { open: !!instalmentToMarkPaidOffline, title: t('markAsPaid'), description: t('markPaidOfflineDescription', {
                fullName: instalmentToMarkPaidOffline === null || instalmentToMarkPaidOffline === void 0 ? void 0 : instalmentToMarkPaidOffline.fullName,
                month: date_1.DateTime.fromISO(instalmentToMarkPaidOffline === null || instalmentToMarkPaidOffline === void 0 ? void 0 : instalmentToMarkPaidOffline.chargeDate).toFormat('LLL yyyy'),
            }), cancelButtonProps: { children: t('no'), onClick: closeMarkPaidOfflineDialog }, confirmButtonProps: {
                children: t('yes'),
                isLoading: isLoadingMarkPaidOffline,
                onClick: () => markInstalmentPaidOffline(instalmentToMarkPaidOffline.id.toString()),
            } }),
        react_1.default.createElement(components_1.ConfirmDialog, { open: !!instalmentToUndoPaidOffline, title: t('undoPaidOffline'), description: t('undoPaidOfflineDescription', {
                fullName: instalmentToUndoPaidOffline === null || instalmentToUndoPaidOffline === void 0 ? void 0 : instalmentToUndoPaidOffline.fullName,
                month: date_1.DateTime.fromISO(instalmentToUndoPaidOffline === null || instalmentToUndoPaidOffline === void 0 ? void 0 : instalmentToUndoPaidOffline.chargeDate).toFormat('LLL yyyy'),
            }), cancelButtonProps: { children: t('no'), onClick: closeUndoPaidOfflineDialog }, confirmButtonProps: {
                children: t('yes'),
                isLoading: isLoadingUndoPaidOffline,
                onClick: () => undoInstalmentPaidOffline(instalmentToUndoPaidOffline.id.toString()),
            } })));
};
exports.ViewLeasePaymentHistoryScreen = ViewLeasePaymentHistoryScreen;
