"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogInButtonFacebook = void 0;
const react_1 = __importStar(require("react"));
const data_tracking_context_1 = require("@guiker/data-tracking-context");
const react_query_1 = require("@guiker/react-query");
const authentication_1 = require("../authentication");
const components_1 = require("../components");
const load_scripts_1 = require("./load-scripts");
const config_1 = require("./config");
const useStyles = components_1.makeStyles({
    button: {
        minHeight: components_1.theme.spacing(7),
        paddingTop: components_1.theme.spacing(1.25),
        paddingBottom: components_1.theme.spacing(1.25),
        paddingLeft: components_1.theme.spacing(1.5),
        paddingRight: components_1.theme.spacing(1.5),
        marginBottom: components_1.theme.spacing(2),
        backgroundColor: '#3664A2',
        borderRadius: 0,
        boxShadow: components_1.theme.shadows.light,
        '&:hover, &:hover.Mui-disabled': {
            backgroundColor: '#3664A2',
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
        },
    },
    icon: {
        marginLeft: components_1.theme.spacing(0.6),
        marginRight: components_1.theme.spacing(0.65),
        width: components_1.theme.spacing(5.5),
        height: components_1.theme.spacing(5.5),
    },
    text: {
        width: '100%',
        textAlign: 'left',
        paddingLeft: '10%',
        color: components_1.theme.palette.common.white,
    },
});
const ButtonIcon = () => {
    const classes = useStyles();
    return react_1.default.createElement("img", { alt: 'Facebook logo', className: classes.icon, src: config_1.config.facebook.logo });
};
const LogInButtonFacebook = ({ authenticator, initialized, isSignUp }) => {
    const classes = useStyles();
    const apiClient = authentication_1.useAuthenticationApiClient();
    const [loaded, setLoaded] = react_1.useState(false);
    const [disabled, setDisabled] = react_1.useState(false);
    const { sendEvent } = data_tracking_context_1.useDataTrackingContext();
    const { invitationToken, setUser } = authentication_1.useAuthenticationContext();
    const [handleLogIn] = react_query_1.useMutation((...args) => apiClient.socialLogIn(...args), {
        onSuccess: (user) => {
            sendEvent({ event: isSignUp ? 'signup' : 'login', userId: user.id.toString() });
            setUser(user);
        },
    });
    react_1.useEffect(() => {
        const { provider, scriptSrc, clientId: appId } = config_1.config.facebook;
        load_scripts_1.loadScript(provider, scriptSrc, () => {
            ;
            window.fbAsyncInit = () => FB.init({ appId, version: 'v6.0' });
            setLoaded(true);
        });
    }, []);
    const onClick = () => {
        if (loaded && !disabled) {
            setDisabled(true);
            FB.login(({ status, authResponse }) => {
                if (status === 'connected') {
                    handleLogIn({
                        payload: {
                            authenticatorId: authenticator.id,
                            password: authResponse.accessToken,
                            invitationToken,
                        },
                    });
                }
                setDisabled(false);
            }, { scope: 'email' });
        }
    };
    return (react_1.default.createElement(data_tracking_context_1.DataTrackingCTA, { key: 'FacebookLogIn' },
        react_1.default.createElement(components_1.Button, { className: classes.button, fullWidth: true, disabled: !initialized || disabled, onClick: onClick, startIcon: react_1.default.createElement(ButtonIcon, null) },
            react_1.default.createElement(components_1.Box, { className: classes.text }, "Login with Facebook"))));
};
exports.LogInButtonFacebook = LogInButtonFacebook;
