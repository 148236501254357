"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.routes = void 0;
const screens_1 = require("../screens");
const routes = {
    listingLeads: {
        exact: true,
        path: '/',
        Component: screens_1.ListListingLeadsScreen,
        requireAuthentication: true,
        authenticationMethod: 'user',
    },
    listingLead: {
        exact: true,
        path: '/:listingLeadId',
        requireAuthentication: true,
        authenticationMethod: 'user',
        Component: screens_1.ViewListingLeadScreen,
    },
};
exports.routes = routes;
