"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListServiceAgreementsScreen = void 0;
const react_1 = __importStar(require("react"));
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const list_service_agreements_columns_1 = require("./list-service-agreements-columns");
const ListServiceAgreementsScreen = () => {
    const { t } = hooks_1.useTranslation(['listServiceAgreementsScreen']);
    const [serviceAgreementIdToCancel, setServiceAgreementIdToCancel] = react_1.useState();
    const adminApiClient = hooks_1.useServiceAgreementAdminApiClient();
    const queryCache = hooks_1.useQueryCache();
    const fetcher = (paginate) => adminApiClient.readAllServiceAgreements({ queryParams: Object.assign({}, paginate) }, { includeMetadata: true });
    const [cancelServiceAgreement, { isLoading }] = hooks_1.useMutation((serviceAgreementId) => adminApiClient.cancelServiceAgreement({
        pathParams: {
            serviceAgreementId,
        },
    }), {
        onSuccess: () => {
            queryCache.refetchQueries('readAllServiceAgreements');
            onCloseCancelDialog();
        },
    });
    const handleCancelDialog = (serviceAgreementId) => setServiceAgreementIdToCancel(serviceAgreementId);
    const onCloseCancelDialog = () => setServiceAgreementIdToCancel(null);
    return (react_1.default.createElement(components_1.BaseScreen, { title: t('listServiceAgreements'), hasDivider: false, maxWidth: '100%' },
        react_1.default.createElement(components_1.Box, { mt: 4 },
            react_1.default.createElement(components_1.PaginatedTable, { queryKey: 'readAllServiceAgreements', fetcher: fetcher, columns: list_service_agreements_columns_1.getServiceAgreementsColumns(handleCancelDialog) })),
        react_1.default.createElement(components_1.ConfirmDialog, { open: serviceAgreementIdToCancel != null, title: t('cancelDialogHeader'), description: t('cancelDialogDescription'), cancelButtonProps: { children: t('common:no'), onClick: onCloseCancelDialog }, confirmButtonProps: {
                children: t('common:yes'),
                isLoading: isLoading,
                type: 'submit',
                onClick: () => cancelServiceAgreement(serviceAgreementIdToCancel),
            }, onClose: onCloseCancelDialog })));
};
exports.ListServiceAgreementsScreen = ListServiceAgreementsScreen;
