"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewButtonContainer = void 0;
const react_1 = __importStar(require("react"));
const react_hook_form_1 = require("@guiker/react-hook-form");
const react_query_1 = require("@guiker/react-query");
const router_1 = require("@guiker/router");
const payout_api_definition_1 = require("@guiker/payout-api-definition");
const icons_1 = require("@guiker/icons");
const payout_entity_1 = require("@guiker/payout-entity");
const hooks_1 = require("../../hooks");
const i18n_1 = require("../../i18n");
const style_1 = require("../../style");
const __1 = require("../");
const useStyle = style_1.makeStyles({
    acceptButton: {
        marginLeft: style_1.theme.spacing(2),
    },
    buttonWithLoader: {
        marginLeft: style_1.theme.spacing(2),
    },
});
const ReviewButtonContainer = ({ payoutMethodId }) => {
    const { t } = i18n_1.useTranslation(['office-payout', 'common']);
    const navigate = router_1.useNavigate();
    const classes = useStyle();
    const adminApiClient = hooks_1.useAdminPayoutApiClient();
    const [openAcceptModal, setOpenAcceptModal] = react_1.useState(false);
    const [openRejectModal, setOpenRejectModal] = react_1.useState(false);
    const [reviewStatus, setReviewStatus] = react_1.useState(null);
    const handleAcceptModal = () => {
        setOpenAcceptModal(!openAcceptModal);
        setReviewStatus(reviewStatus ? null : payout_entity_1.PayoutMethodStatus.ENABLED);
    };
    const handleRejectModal = () => {
        setOpenRejectModal(!openRejectModal);
        setReviewStatus(reviewStatus ? null : payout_entity_1.PayoutMethodStatus.REJECTED);
    };
    const [reviewPayoutMethod, { isLoading }] = react_query_1.useMutation((payload) => adminApiClient.updatePayoutMethodReview({
        pathParams: { payoutMethodId },
        payload: Object.assign(Object.assign({}, payload), { status: reviewStatus }),
    }), {
        onSuccess: () => {
            reviewStatus === payout_entity_1.PayoutMethodStatus.ENABLED ? handleAcceptModal() : handleRejectModal();
            navigate('..');
        },
    });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.Box, { p: 3, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' },
            react_1.default.createElement(__1.Button, { color: 'alert', colorVariant: 'background', startIcon: react_1.default.createElement(icons_1.CloseIcon, null), onClick: handleRejectModal }, t('office-payout:payoutMethodDetails.modal.reject')),
            react_1.default.createElement(__1.Button, { className: classes.acceptButton, color: 'primary', startIcon: react_1.default.createElement(icons_1.CheckmarkIcon, null), onClick: handleAcceptModal }, t('office-payout:payoutMethodDetails.modal.accept'))),
        react_1.default.createElement(__1.ApiForm, { formOptions: { resolver: react_hook_form_1.yupResolver(payout_api_definition_1.AdminApi.updateReviewPayloadSchema) } },
            react_1.default.createElement(__1.ConfirmDialog, { open: openAcceptModal, title: t('office-payout:payoutMethodDetails.modal.acceptTitle'), description: t('office-payout:payoutMethodDetails.modal.acceptSubtext'), onClose: handleAcceptModal, cancelButtonProps: { onClick: handleAcceptModal, children: t('common:no') } },
                react_1.default.createElement(__1.ApiFormAction, { onClick: reviewPayoutMethod },
                    react_1.default.createElement(__1.ButtonWithLoader, { className: classes.buttonWithLoader, isLoading: isLoading }, t('common:yes')))),
            react_1.default.createElement(__1.ConfirmDialog, { open: openRejectModal, title: t('office-payout:payoutMethodDetails.modal.rejectTitle'), description: react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(__1.P, null, t('office-payout:payoutMethodDetails.modal.rejectSubtext')),
                    react_1.default.createElement(__1.TextField, { name: 'comment', label: t('office-payout:payoutMethodDetails.modal.rejectInputLabel'), multiline: true, rows: 3 })), onClose: handleRejectModal, cancelButtonProps: { onClick: handleRejectModal, children: t('common:cancel') } },
                react_1.default.createElement(__1.ApiFormAction, { onClick: reviewPayoutMethod },
                    react_1.default.createElement(__1.ButtonWithLoader, { type: 'submit', color: 'alert', className: classes.buttonWithLoader, isLoading: isLoading }, t('office-payout:payoutMethodDetails.modal.reject')))))));
};
exports.ReviewButtonContainer = ReviewButtonContainer;
