"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FurnitureSection = void 0;
const react_1 = __importStar(require("react"));
const i18n_1 = require("@guiker/i18n");
const form_1 = require("../../form");
const hooks_1 = require("../../hooks");
const __1 = require("..");
const FurnitureSection = () => {
    var _a;
    const { t } = i18n_1.useTranslation('common');
    const prefix = 'unit.furniture';
    const itemPrefix = `${prefix}.items`;
    const { listingLead: { unit }, } = hooks_1.useListingLead();
    const furnishIncluded = form_1.useWatch({ name: `${prefix}.isIncluded` });
    const bedCount = form_1.useWatch({ name: `${itemPrefix}.bed.count` });
    const createBedSizes = () => {
        const beds = [];
        for (let i = 0; i < bedCount; i++) {
            beds.push(react_1.default.createElement(__1.Grid, { item: true, xs: 12, sm: 6, key: `bedSize-${i}` },
                react_1.default.createElement(form_1.TextField, { maxWidth: '100%', name: `${itemPrefix}.bed.sizes[${i}]`, label: t('bedSize', { bedIndex: i + 1 }) })));
        }
        return beds;
    };
    const { current: furnitureItems } = react_1.useRef([
        { name: 'table', adornment: react_1.default.createElement(__1.TableIcon, null) },
        { name: 'chair', adornment: react_1.default.createElement(__1.ChairIcon, null) },
        { name: 'chestOfDrawer', adornment: react_1.default.createElement(__1.DrawerChestIcon, null) },
        { name: 'couch', adornment: react_1.default.createElement(__1.CouchIcon, null) },
        { name: 'armchair', adornment: react_1.default.createElement(__1.ArmchairIcon, null) },
        { name: 'bed', adornment: react_1.default.createElement(__1.BedIcon, null) },
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(__1.Box, { mb: 3 },
            react_1.default.createElement(__1.H4, null, t('isTheUnitFurnished')),
            react_1.default.createElement(form_1.RadioGroup, { name: `${prefix}.isIncluded`, maxWidth: '100%', direction: 'row', defaultValue: ((_a = unit === null || unit === void 0 ? void 0 : unit.furniture) === null || _a === void 0 ? void 0 : _a.isIncluded) || 'NOT_INCLUDED', gridContainerProps: { wrap: 'nowrap' }, options: [
                    { label: t('yes'), value: 'INCLUDED', gridItemProps: { sm: 2 } },
                    { label: t('no'), value: 'NOT_INCLUDED', gridItemProps: { sm: 2 } },
                    { label: t('canFurnishForAnExtraFee'), value: 'CAN_INCLUDE_WITH_EXTRA_FEE', gridItemProps: { sm: 5 } },
                ] }),
            react_1.default.createElement(__1.Grid, { container: true },
                react_1.default.createElement(__1.Grid, { item: true, xs: 12, sm: 6 },
                    react_1.default.createElement(__1.Box, { hidden: furnishIncluded !== 'CAN_INCLUDE_WITH_EXTRA_FEE', mt: 2 },
                        react_1.default.createElement(form_1.TextField, { required: furnishIncluded === 'CAN_INCLUDE_WITH_EXTRA_FEE', name: `${prefix}.extraFee`, maxWidth: '100%', label: t('whatIsTheMonthlyFee') }))))),
        react_1.default.createElement(__1.Box, { mb: 3 },
            react_1.default.createElement(__1.H4, null, t('pleaseSpecify')),
            furnitureItems.map(({ name, adornment }, index) => (react_1.default.createElement(form_1.NumericInput, { key: index, name: `${itemPrefix}.${name}.count`, label: t(name), adornment: adornment }))),
            react_1.default.createElement(__1.Box, { mt: 2 },
                react_1.default.createElement(__1.Grid, { container: true, spacing: 2 }, bedCount > 0 && createBedSizes())),
            react_1.default.createElement(form_1.TextField, { maxWidth: '100%', name: `${prefix}.additionalInfo`, label: t('specifyAnythingElseHere') }))));
};
exports.FurnitureSection = FurnitureSection;
