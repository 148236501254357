"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialLogIn = void 0;
const react_1 = __importStar(require("react"));
const react_query_1 = require("@guiker/react-query");
const authentication_1 = require("../authentication");
const LogInButtonGoogle_1 = require("./LogInButtonGoogle");
const LogInButtonFacebook_1 = require("./LogInButtonFacebook");
const SocialLogIn = ({ isSignUp = false }) => {
    const apiClient = authentication_1.useAuthenticationApiClient();
    const [authenticators, setAuthenticators] = react_1.useState([]);
    const { isSuccess: initialized } = react_query_1.useQuery('authenticators', _ => apiClient.getAllAuthenticators(), {
        onSuccess: authenticators => setAuthenticators(authenticators),
    });
    const socialLogInButtons = [
        {
            key: 'google',
            Component: LogInButtonGoogle_1.LogInButtonGoogle,
            authenticator: authenticators.find(auth => auth.provider === 'GOOGLE' && auth.enabled),
        },
        {
            key: 'facebook',
            Component: LogInButtonFacebook_1.LogInButtonFacebook,
            authenticator: authenticators.find(auth => auth.provider === 'FACEBOOK' && auth.enabled),
        },
    ];
    return (react_1.default.createElement(react_1.default.Fragment, null, socialLogInButtons.map(({ Component, authenticator, key }) => (react_1.default.createElement(Component, Object.assign({ key: key, isSignUp: isSignUp }, { authenticator, initialized }))))));
};
exports.SocialLogIn = SocialLogIn;
