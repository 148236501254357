"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogInButtonGoogle = void 0;
const react_1 = __importStar(require("react"));
const data_tracking_context_1 = require("@guiker/data-tracking-context");
const react_query_1 = require("@guiker/react-query");
const authentication_1 = require("../authentication");
const components_1 = require("../components");
const load_scripts_1 = require("./load-scripts");
const config_1 = require("./config");
const useStyles = components_1.makeStyles({
    button: {
        minHeight: components_1.theme.spacing(7),
        paddingTop: components_1.theme.spacing(0.75),
        paddingBottom: components_1.theme.spacing(0.75),
        paddingLeft: components_1.theme.spacing(1.5),
        paddingRight: components_1.theme.spacing(1.5),
        marginBottom: components_1.theme.spacing(2),
        backgroundColor: '#4384EA',
        borderRadius: 0,
        boxShadow: components_1.theme.shadows.light,
        '&:hover': {
            backgroundColor: '#4384EA',
        },
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
            flexWrap: 'nowrap',
        },
    },
    icon: {
        width: components_1.theme.spacing(6.75),
        height: components_1.theme.spacing(6.75),
    },
    text: {
        width: '100%',
        textAlign: 'left',
        paddingLeft: '10%',
        color: components_1.theme.palette.common.white,
    },
});
const ButtonIcon = () => {
    const classes = useStyles();
    return react_1.default.createElement("img", { alt: 'Google logo', className: classes.icon, src: config_1.config.google.logo });
};
const LogInButtonGoogle = ({ authenticator, initialized, isSignUp }) => {
    const classes = useStyles();
    const apiClient = authentication_1.useAuthenticationApiClient();
    const [authClient, setAuthClient] = react_1.useState();
    const { sendEvent } = data_tracking_context_1.useDataTrackingContext();
    const { invitationToken, setUser } = authentication_1.useAuthenticationContext();
    const [handleLogIn] = react_query_1.useMutation((...args) => apiClient.socialLogIn(...args), {
        onSuccess: (user) => {
            sendEvent({ event: isSignUp ? 'signup' : 'login', userId: user.id.toString() }), setUser(user);
        },
    });
    react_1.useEffect(() => {
        const { provider, scriptSrc, clientId: client_id } = config_1.config.google;
        load_scripts_1.loadScript(provider, scriptSrc, () => {
            gapi.load('auth2', () => setAuthClient(gapi.auth2.init({ client_id })));
        });
    }, []);
    const onClick = () => __awaiter(void 0, void 0, void 0, function* () {
        if (authClient) {
            const auth = yield authClient.grantOfflineAccess();
            handleLogIn({
                payload: {
                    authenticatorId: authenticator.id,
                    password: auth.code,
                    invitationToken,
                },
            });
        }
    });
    return (react_1.default.createElement(data_tracking_context_1.DataTrackingCTA, { key: 'GoogleLogIn' },
        react_1.default.createElement(components_1.Button, { className: classes.button, fullWidth: true, disabled: !initialized || !authClient, onClick: onClick, startIcon: react_1.default.createElement(ButtonIcon, null) },
            react_1.default.createElement(components_1.Box, { className: classes.text }, "Login with Google"))));
};
exports.LogInButtonGoogle = LogInButtonGoogle;
