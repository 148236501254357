"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListingLead = void 0;
const react_1 = require("react");
const context_1 = require("../context");
const useListingLead = () => {
    const context = react_1.useContext(context_1.ListingLeadContext);
    if (context === undefined) {
        throw new Error('useListingLead can only be used inside ListingLeadContextProvider');
    }
    return context;
};
exports.useListingLead = useListingLead;
