"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LegacyAppApiClient = void 0;
const legacy_rest_client_1 = require("@guiker/legacy-rest-client");
const BASE_APP_API_PATH = 'app_api';
const BASE_LISTING_PATH = `${BASE_APP_API_PATH}/listings`;
const BASE_LEASE_PATH = `${BASE_APP_API_PATH}/leases`;
const BASE_INSTALMENT_PATH = `${BASE_APP_API_PATH}/instalments`;
const endpoints = {
    readEffectiveLeasesByListingId: (listingId, page, perPage) => `${BASE_LISTING_PATH}/${listingId}/effective_leases?page=${page}&perPage=${perPage}`,
    readLeasePaymentHistory: (leaseId) => `${BASE_LEASE_PATH}/${leaseId}/payment_history`,
    terminateLease: (leaseId) => `${BASE_LEASE_PATH}/${leaseId}/terminate`,
    createTransactionForOfflinePaid: (instalmentId) => `${BASE_INSTALMENT_PATH}/${instalmentId}/offline_charge`,
    deleteTransactionForOfflinePaid: (instalmentId) => `${BASE_INSTALMENT_PATH}/${instalmentId}/undo_offline_paid`,
};
const LegacyAppApiClient = ({ baseUrl, accessToken }) => {
    const baseClient = legacy_rest_client_1.LegacyRestClient(baseUrl, null, {
        'Access-Token': accessToken,
    });
    const buildMeta = ({ total, page, perPage }) => ({
        total,
        totalPages: Math.floor(total / perPage) + (total % perPage === 0 ? 0 : 1),
        page,
        perPage,
    });
    return {
        readEffectiveLeasesByListingId: ({ listingId, page = 1, perPage = 20, }) => __awaiter(void 0, void 0, void 0, function* () {
            const leasesResponse = yield baseClient
                .get(endpoints.readEffectiveLeasesByListingId(listingId, page - 1, perPage))
                .then(r => r.body);
            const total = leasesResponse.meta.total;
            return {
                meta: buildMeta({ total, page, perPage }),
                data: leasesResponse.data,
            };
        }),
        readLeasePaymentHistory: ({ leaseId, page = 1, perPage = 20, }) => __awaiter(void 0, void 0, void 0, function* () {
            const inventoryTransactionOverview = yield baseClient
                .get(endpoints.readLeasePaymentHistory(leaseId))
                .then(r => r.body.data);
            const tenantTransactions = inventoryTransactionOverview.tenants
                .flatMap(tenant => {
                return tenant.instalments.map(instalment => {
                    return Object.assign(Object.assign({}, instalment), { userId: tenant.leaseUser.user.id, fullName: [tenant.leaseUser.user.firstName, tenant.leaseUser.user.lastName].join(' ') });
                });
            })
                .sort((a, b) => {
                if (a.chargeDate > b.chargeDate) {
                    return 1;
                }
                else if (a.chargeDate < b.chargeDate) {
                    return -1;
                }
                else {
                    return a.userId >= b.userId ? 1 : -1;
                }
            });
            const total = tenantTransactions.length;
            return {
                meta: buildMeta({ total, page, perPage }),
                data: tenantTransactions.slice((page - 1) * perPage, page * perPage),
            };
        }),
        terminateLease: ({ leaseId }) => __awaiter(void 0, void 0, void 0, function* () { return baseClient.put(endpoints.terminateLease(leaseId)); }),
        createTransactionForOfflinePaid: ({ instalmentId }) => __awaiter(void 0, void 0, void 0, function* () { return baseClient.post(endpoints.createTransactionForOfflinePaid(instalmentId), {}); }),
        deleteTransactionForOfflinePaid: ({ instalmentId }) => __awaiter(void 0, void 0, void 0, function* () { return baseClient.delete(endpoints.deleteTransactionForOfflinePaid(instalmentId)); }),
    };
};
exports.LegacyAppApiClient = LegacyAppApiClient;
