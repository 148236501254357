"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = void 0;
const ui_common_1 = require("@guiker/ui-common");
const config = {
    google: {
        provider: 'google',
        scriptSrc: 'https://apis.google.com/js/client:platform.js',
        clientId: '831385563023-3ss9nf2ladj87naehug7jn298mbqc9fo.apps.googleusercontent.com',
        logo: `${ui_common_1.cloudfrontAssetsBaseUrl}/login/logo-google.svg`,
    },
    facebook: {
        provider: 'facebook',
        scriptSrc: 'https://connect.facebook.net/en_US/sdk.js',
        clientId: '1758459664300942',
        logo: `${ui_common_1.cloudfrontAssetsBaseUrl}/login/logo-facebook.png`,
    },
};
exports.config = config;
