"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateServiceAgreementForm = void 0;
const react_1 = __importStar(require("react"));
const lodash_1 = require("@guiker/lodash");
const service_agreement_entity_1 = require("@guiker/service-agreement-entity");
const service_agreement_api_definition_1 = require("@guiker/service-agreement-api-definition");
const react_hook_form_1 = require("@guiker/react-hook-form");
const style_1 = require("../../style");
const hooks_1 = require("../../hooks");
const components_1 = require("../../components");
const useStyle = style_1.makeStyles({
    hideButton: {
        display: 'none',
    },
});
const CreateServiceAgreementForm = () => {
    const classes = useStyle();
    const { serviceAgreementId, serviceAgreement, setServiceAgreement, readOnly } = hooks_1.useServiceAgreementContext();
    const [openConfirmModal, setOpenConfirmModal] = react_1.useState(false);
    const [openSaveModal, setOpenSaveModal] = react_1.useState(false);
    const { t } = hooks_1.useTranslation();
    const apiClient = hooks_1.useServiceAgreementAdminApiClient();
    const serviceAgreementSections = serviceAgreement === null || serviceAgreement === void 0 ? void 0 : serviceAgreement.sections;
    const sections = {
        sections: {
            clientName: { label: serviceAgreementSections.clientName.label },
            objective: { label: serviceAgreementSections.objective.label },
            guikerServices: { label: serviceAgreementSections.guikerServices.label },
            clientAgreesTo: { label: serviceAgreementSections.clientAgreesTo.label },
            fees: { label: serviceAgreementSections.fees.label, subText: serviceAgreementSections.fees.subText },
            term: { label: serviceAgreementSections.term.label },
            guikerSignature: { title: serviceAgreementSections.guikerSignature.title },
        },
    };
    const [saveServiceAgreement, { isLoading: isSaving }] = hooks_1.useMutation((payload) => __awaiter(void 0, void 0, void 0, function* () {
        return apiClient.updateServiceAgreement({
            pathParams: { serviceAgreementId },
            payload: lodash_1.defaultsDeep(payload, serviceAgreement),
        });
    }), {
        onSuccess: () => setOpenSaveModal(true),
    });
    const cancelSend = () => setOpenConfirmModal(false);
    const onCloseSaveModal = () => setOpenSaveModal(false);
    return (react_1.default.createElement(components_1.ApiForm, { formOptions: {
            resolver: react_hook_form_1.yupResolver(service_agreement_api_definition_1.AdminApi.Schema.updateServiceAgreement, {
                defaultValues: Object.assign(Object.assign({}, serviceAgreement), sections),
            }),
            defaultValues: serviceAgreement,
        }, apiOptions: {
            onSuccess: (serviceAgreement) => setServiceAgreement(serviceAgreement),
        }, formName: 'CreateServiceAgreement' },
        react_1.default.createElement(components_1.ServiceAgreementComponent, { serviceAgreement: serviceAgreement }),
        serviceAgreement.status !== service_agreement_entity_1.ServiceAgreementStatus.signed && (react_1.default.createElement(components_1.Box, { mx: 2, mb: 6, display: 'flex' },
            react_1.default.createElement(components_1.Box, { mr: 3 },
                react_1.default.createElement(components_1.Button, { onClick: () => setOpenConfirmModal(true), disabled: readOnly }, t('createServiceAgreement:sendAgreement'))),
            react_1.default.createElement(components_1.Box, null,
                react_1.default.createElement(components_1.ApiFormAction, { onClick: saveServiceAgreement },
                    react_1.default.createElement(components_1.ButtonWithLoader, { disabled: readOnly, type: 'submit', isLoading: isSaving, secondaryButton: true }, t('common:save')))))),
        react_1.default.createElement(components_1.ConfirmationModal, { open: openConfirmModal, onClose: cancelSend }),
        react_1.default.createElement(components_1.ConfirmDialog, { open: openSaveModal, title: t('createServiceAgreement:saveModalTitle'), onClose: onCloseSaveModal, cancelButtonProps: { children: t('common:close'), onClick: onCloseSaveModal }, confirmButtonProps: {
                className: classes.hideButton,
            } })));
};
exports.CreateServiceAgreementForm = CreateServiceAgreementForm;
