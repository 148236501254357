"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateListingDescription = void 0;
const listing_lead_entity_1 = require("@guiker/listing-lead-entity");
const getCityParams = (city) => {
    return city === listing_lead_entity_1.CityName.MONTREAL
        ? {
            name: 'Montreal',
            state: 'QC',
            country: 'CA',
        }
        : {
            name: 'Toronto',
            state: 'ON',
            country: 'CA',
        };
};
const generateListingDescription = (listingLead, t) => {
    const { address, unit, rules, building } = listingLead;
    const { streetNumber, streetName, zipcode, city } = address;
    let addressDescription = `${[
        [streetNumber, streetName].join(' '),
        getCityParams(city).name,
        zipcode,
        getCityParams(city).state,
        getCityParams(city).country,
    ].join(', ')}.`;
    if (unit.suiteNumber) {
        addressDescription += `${unit.suiteNumber}. `;
    }
    const unitDescription = `This ${unit.unitSquareFeetSize ? unit.unitSquareFeetSize + ' ' : ''}unit is ${unit.furniture.isIncluded === 'INCLUDED' ? 'furnished' : 'not furnished'}. `;
    const petsDescription = `${rules.smallPets === 'INCLUDED' || rules.largePets === 'INCLUDED' ? 'Pets are allowed. ' : ''}`;
    const smokingDescription = `${rules.smoking === 'INCLUDED' ? 'Smoking allowed' : 'No smoking allowed'}. `;
    const notIncluded = [];
    const includedInUnit = [];
    const includedInBuilding = [];
    Object.keys(unit.utility).forEach(utilityName => unit.utility[utilityName].isIncludedInPrice === 'INCLUDED'
        ? includedInUnit.push(t(utilityName))
        : notIncluded.push(t(utilityName)));
    if (unit.parking.isIncluded === 'INCLUDED') {
        includedInUnit.push('Parking');
    }
    else if (unit.parking.isIncluded === 'STREET') {
        includedInUnit.push('Street parking');
    }
    else if (unit.parking.isIncluded === 'CAN_INCLUDE_WITH_EXTRA_FEE') {
        includedInUnit.push(`Parking ${unit.parking.extraFee ? `(${unit.parking.extraFee})` : '(extra fee)'}`);
    }
    else {
        notIncluded.push('Parking');
    }
    if (unit.storage.isIncluded === 'INCLUDED') {
        includedInUnit.push('Locker');
    }
    else if (unit.storage.isIncluded === 'CAN_INCLUDE_WITH_EXTRA_FEE') {
        includedInUnit.push(`Locker${unit.storage.extraFee ? `(${unit.storage.extraFee})` : 'extra fee'}`);
    }
    else {
        notIncluded.push('Locker');
    }
    Object.keys(unit.appliance).forEach(applianceName => {
        if (unit.appliance[applianceName] === 'INCLUDED') {
            includedInUnit.push(t(applianceName));
        }
        else if (unit.appliance[applianceName] === 'IN_BUILDING') {
            includedInBuilding.push(t(applianceName));
        }
        else {
            notIncluded.push(t(applianceName));
        }
    });
    Object.keys(building.amenity).forEach(amenityName => building.amenity[amenityName] ? includedInBuilding.push(t(amenityName)) : notIncluded.push(t(amenityName)));
    return ('<div>' +
        `${addressDescription}<br><br>` +
        `${unitDescription}${petsDescription}${smokingDescription}<br><br>` +
        `<strong>Included in this unit: </strong>${includedInUnit.join(', ')}.<br><br>` +
        `<strong>Included in the building: </strong>${includedInBuilding.join(', ')}.<br><br>` +
        `<strong>Not included: </strong>${notIncluded.join(', ')}.` +
        '</div>');
};
exports.generateListingDescription = generateListingDescription;
