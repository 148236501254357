import React from 'react'
import { BrowserRouter } from '@guiker/router'
import App from './App'

export const ClientApp: React.FC = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}
