"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListListingLeadsScreen = void 0;
const react_1 = __importStar(require("react"));
const components_library_1 = require("@guiker/components-library");
const react_query_components_1 = require("@guiker/react-query-components");
const react_query_1 = require("@guiker/react-query");
const hooks_1 = require("../../hooks");
const listing_lead_columns_1 = require("./listing-lead-columns");
const ListListingLeadsScreen = () => {
    const { t } = hooks_1.useTranslation(['listListingLeadsScreen']);
    const [listingLeadIdToRemove, setListingLeadIdToRemove] = react_1.useState();
    const apiClient = hooks_1.useListingLeadApiClient();
    const queryCache = react_query_1.useQueryCache();
    const fetcher = (paginate) => apiClient.readAllListingLeads({ queryParams: paginate }, { includeMetadata: true });
    const removeScrappedListing = (listingLeadId) => () => setListingLeadIdToRemove(listingLeadId);
    const [deleteListingLead, { isLoading }] = hooks_1.useMutation((listingLeadId) => apiClient.removeOneListingLead({
        pathParams: {
            listingLeadId,
        },
    }), {
        onSuccess: () => {
            queryCache.refetchQueries('readAllListingLeads');
            setListingLeadIdToRemove(null);
        },
    });
    const cancelRemoval = () => setListingLeadIdToRemove(null);
    return (react_1.default.createElement(components_library_1.BaseScreen, { title: t('scrapedListingManagement'), hasDivider: false, maxWidth: '100%' },
        react_1.default.createElement(components_library_1.Box, { mt: 4 },
            react_1.default.createElement(react_query_components_1.PaginatedTable, { queryKey: 'readAllListingLeads', fetcher: fetcher, columns: listing_lead_columns_1.getListingLeadColumns(removeScrappedListing) })),
        react_1.default.createElement(components_library_1.ConfirmDialog, { open: !!listingLeadIdToRemove, title: t('removeListing'), description: t('removeListingDescription'), cancelButtonProps: { children: t('cancel'), onClick: cancelRemoval }, confirmButtonProps: {
                children: t('remove'),
                color: 'alert',
                isLoading: isLoading,
                onClick: () => deleteListingLead(listingLeadIdToRemove),
            } })));
};
exports.ListListingLeadsScreen = ListListingLeadsScreen;
