"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePostMessage = void 0;
const react_1 = require("react");
const usePostMessage = (event, handler) => {
    const postMessage = () => {
        /* eslint-disable no-restricted-globals */
        window.postMessage(event, location.origin);
    };
    const validate = (e) => {
        const postedEvent = e.data;
        /* eslint-disable no-restricted-globals */
        return postedEvent.type === event.type && e.origin === location.origin;
    };
    const getData = (e) => {
        const postedEvent = e.data;
        return postedEvent.data;
    };
    react_1.useEffect(() => {
        if (handler) {
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
            window.removeEventListener('message', handler, false);
            window.addEventListener('message', handler, false);
            return () => window.removeEventListener('message', handler, false);
        }
    }, [handler]);
    return { postMessage, validate, getData };
};
exports.usePostMessage = usePostMessage;
