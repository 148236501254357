"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const components_1 = require("../../components");
exports.styles = {
    boxWithShadow: {
        padding: components_1.theme.spacing(3),
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
        borderRadius: components_1.theme.spacing(2),
    },
    inputItem: {
        maxWidth: 256,
        width: '100%',
    },
};
