import React from 'react'
import { H1, Box } from '../../components'

const Home: React.FC = () => {
  return (
    <Box p={3}>
      <H1>Home</H1>
    </Box>
  )
}

export { Home }
