"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingUnitAmenity = void 0;
const react_1 = __importStar(require("react"));
const i18n_1 = require("@guiker/i18n");
const icons_1 = require("../icons");
const form_1 = require("../../form");
const __1 = require("..");
const _1 = require(".");
const ListingUnitAmenity = ({ className }) => {
    const { t } = i18n_1.useTranslation('common');
    const prefix = 'unit';
    const roomPrefix = `${prefix}.room`;
    const appliancePrefix = `${prefix}.appliance`;
    const { current: threeOptions } = react_1.useRef([
        { label: t('yes'), value: 'INCLUDED' },
        { label: t('inBuilding'), value: 'IN_BUILDING' },
        { label: t('no'), value: 'NOT_INCLUDED' },
    ]);
    const { current: roomCounts } = react_1.useRef([
        { name: 'bedroomCount', adornment: react_1.default.createElement(icons_1.CondoIcon, null) },
        { name: 'livingroomCount', adornment: react_1.default.createElement(icons_1.LivingRoomIcon, null) },
        { name: 'bathroomCount', adornment: react_1.default.createElement(icons_1.BathroomIcon, null) },
        { name: 'kitchenCount', adornment: react_1.default.createElement(icons_1.KitchenIcon, null) },
    ]);
    const { current: availableAppliances } = react_1.useRef([
        { adornmentNode: icons_1.WashingMachineIcon, name: 'washer', options: threeOptions },
        { adornmentNode: icons_1.DryerIcon, name: 'dryer', options: threeOptions },
        { adornmentNode: icons_1.DishwasherIcon, name: 'dishwasher' },
        { adornmentNode: icons_1.OvenIcon, name: 'oven' },
        { adornmentNode: icons_1.StoveIcon, name: 'stove' },
        { adornmentNode: icons_1.FridgeIcon, name: 'fridge' },
        { adornmentNode: icons_1.FreezerIcon, name: 'freezer' },
        { adornmentNode: icons_1.MicrowaveIcon, name: 'microwave' },
        { adornmentNode: icons_1.AirConditionerIcon, name: 'airConditioner' },
        { adornmentNode: icons_1.TVIcon, name: 'tv' },
        { adornmentNode: icons_1.OutdoorsBalconyIcon, name: 'balcony' },
    ]);
    return (react_1.default.createElement(__1.Box, { className: className },
        react_1.default.createElement(__1.H3, { mb: 3 }, t('roomsAndAmenities')),
        react_1.default.createElement(__1.Box, { mb: 3 },
            react_1.default.createElement(__1.H4, null, t('rooms')),
            react_1.default.createElement(__1.Grid, { container: true }, roomCounts.map(({ name, adornment }, index) => (react_1.default.createElement(__1.Grid, { key: index, item: true, xs: 12, sm: 6 },
                react_1.default.createElement(form_1.NumericInput, { name: `${roomPrefix}.${name}`, label: t(name), adornment: adornment })))))),
        react_1.default.createElement(__1.Box, { mb: 3 },
            react_1.default.createElement(__1.H4, null, t('availableAppliances')),
            availableAppliances.map((_a, index) => {
                var { name } = _a, appliance = __rest(_a, ["name"]);
                return (react_1.default.createElement(_1.RadioGroupWithAdornmentLabel, Object.assign({ key: index, name: `${appliancePrefix}.${name}`, text: t(name) }, appliance)));
            })),
        react_1.default.createElement(_1.FurnitureSection, null),
        react_1.default.createElement(_1.StorageSection, null),
        react_1.default.createElement(_1.ParkingSection, null),
        react_1.default.createElement(_1.UtilitySection, null)));
};
exports.ListingUnitAmenity = ListingUnitAmenity;
