import './fonts/gilroy/Gilroy-Regular.woff'
import './fonts/gilroy/Gilroy-Bold.woff'
import './fonts/inter/Inter-Bold.woff'
import './fonts/inter/Inter-Regular.woff'

import React from 'react'
import { render } from 'react-dom'
import { ClientApp } from './ClientApp'

import './fonts.css'

render(
  // <React.StrictMode>
  <ClientApp />,
  // </React.StrictMode>,
  document.getElementById('root'),
)
