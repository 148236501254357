import React from 'react'
import { useLayoutContext } from '../../hooks'
import { AppBar } from '..'
import { Box, makeStyles } from '@guiker/components-library'
import { clsx } from '@guiker/clsx'

export type PageProps = {}

const useStyles = makeStyles({
  shiftedContent: ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: isMobile ? 0 : 0,
  }),
})

const Page: React.FC<PageProps> = ({ children }) => {
  const { appBarIsDisplayed, isMobile } = useLayoutContext()
  const classes = useStyles({ isMobile })

  return (
    <>
      {appBarIsDisplayed && <AppBar />}
      <Box className={clsx({ [classes.shiftedContent]: appBarIsDisplayed })}>{children}</Box>
    </>
  )
}

export { Page }
