"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assets = exports.cloudfrontAssetsBaseUrl = void 0;
exports.cloudfrontAssetsBaseUrl = 'https://d1kuufrg9kru54.cloudfront.net/assets';
exports.assets = {
    svg: {
        logoWithText: `${exports.cloudfrontAssetsBaseUrl}/main/svg/guiker-logo-w-text.svg`,
        closeIcon: `${exports.cloudfrontAssetsBaseUrl}/navbar/icon-close.svg`,
        chevronDown: `${exports.cloudfrontAssetsBaseUrl}/main/svg/chevron-down.svg`,
    },
};
