import { I18nextProvider, initialize } from '@guiker/i18n'

import en from './en'
import fr from './fr'
import zh from './zh'

const i18n = initialize({
  resources: {
    en,
    fr,
    zh,
  },
})

export { I18nextProvider, i18n }
