import React, { useEffect, useState } from 'react'
import { Redirect } from '@guiker/router'
import { AppRole, User } from '@guiker/authentication-entity'
import { useMutation } from '@guiker/react-query'
import { H2, Box, NarrowContent, LogInForm, makeStyles, theme, Spinner, FeedbackComponent } from '../../components'
import { useAuthenticationContext, useAuthenticationAppApiClient, useTranslation } from '../../hooks'

const useStyles = makeStyles({
  container: {
    padding: theme.spacing(3),
    height: '90vh',
  },
})

const LogIn: React.FC = () => {
  const { user, unsetUser } = useAuthenticationContext()
  const classes = useStyles()
  const { t } = useTranslation()

  const [roles, setRoles] = useState<AppRole[]>()
  const appApiClient = useAuthenticationAppApiClient()
  const [fetchUserAppRoles, { isLoading: isLoadingRoles, isIdle, isSuccess }] = useMutation((user: User) =>
    appApiClient.fetchUserAppRoles(user.accessToken, user.id),
  )

  useEffect(() => {
    if (user) {
      fetchUserAppRoles(user, { onSuccess: setRoles })
    }
  }, [user, fetchUserAppRoles])

  if (user && (isLoadingRoles || isIdle || (isSuccess && !roles))) {
    return <Spinner />
  }

  if (user && roles && roles.length > 0) {
    return <Redirect path={'/'} />
  }

  if (user && (!roles || roles.length === 0)) {
    return <FeedbackComponent toasterMessage={t('unauthorized')} onToasterClose={unsetUser} />
  }

  return (
    <NarrowContent>
      <Box className={classes.container}>
        <H2>Sign In</H2>
        <LogInForm showSocialLogIn={false} />
      </Box>
    </NarrowContent>
  )
}

export { LogIn }
