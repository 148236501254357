"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListingTitle = void 0;
const react_1 = __importStar(require("react"));
const i18n_1 = require("@guiker/i18n");
const components_core_1 = require("@guiker/components-core");
const react_hook_form_1 = require("@guiker/react-hook-form");
const hooks_1 = require("../../hooks");
const form_1 = require("../../form");
const __1 = require("..");
const helper_1 = require("./helper");
const useStyles = components_core_1.makeStyles({
    outlinedButton: {
        color: components_core_1.theme.palette.primary.main,
        borderColor: components_core_1.theme.palette.primary.main,
    },
});
const ListingTitle = ({ className }) => {
    const { getValues, setValue } = react_hook_form_1.useFormContext();
    const { t } = i18n_1.useTranslation('common');
    const classes = useStyles();
    const sharedProps = { maxWidth: '100%' };
    const { listingLead } = hooks_1.useListingLead();
    const { title } = listingLead;
    const [description, setDescription] = react_1.useState(listingLead === null || listingLead === void 0 ? void 0 : listingLead.description);
    const generateDescription = () => {
        const generatedDescription = helper_1.generateListingDescription(getValues(), t);
        setDescription(generatedDescription);
        setValue('description', generatedDescription);
    };
    return (react_1.default.createElement(__1.Box, { className: className },
        react_1.default.createElement(__1.H3, { mt: 1 }, t('title')),
        react_1.default.createElement(form_1.TextField, Object.assign({ name: 'title', label: t('listingTitle'), defaultValue: title || undefined }, sharedProps)),
        react_1.default.createElement(__1.Grid, { container: true, direction: 'row', justify: 'space-between' },
            react_1.default.createElement(__1.Grid, { item: true },
                react_1.default.createElement(__1.H3, { mt: 1 }, t('description'))),
            react_1.default.createElement(__1.Grid, { item: true },
                react_1.default.createElement(__1.OutlinedButton, { className: classes.outlinedButton, onClick: generateDescription }, t('autoGenerate')))),
        react_1.default.createElement(__1.RichTextEditor, { name: 'description', label: t('listingDescription'), defaultValue: description || undefined })));
};
exports.ListingTitle = ListingTitle;
